import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTimeout } from '@fuse/hooks';
import AmplService from 'app/services/amplService';
import { useAuth0 } from '@auth0/auth0-react';
import { redirectToLogin } from 'app/services/apiService';
import ErrorMonitor from '../../services/errorMonitor/errorMonitor';

const Callback = props => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  useEffect(() => {
    AmplService.sendEvent(AmplService.EVENTS.CALLBACK_START);
    return () => {
      AmplService.sendEvent(AmplService.EVENTS.CALLBACK_END);
    };
  }, []);
  useTimeout(async () => {
    console.log('callback timeout');
    if (!isAuthenticated) {
      AmplService.sendEvent(AmplService.EVENTS.LOGIN_AUTH_EVENT_NOT_FIRED);
      ErrorMonitor.captureMessage('Authentication event not fired', {
        isAuthenticated,
        isLoading
      });
    } else {
      AmplService.sendEvent(AmplService.EVENTS.LOGIN_AUTH_EVENT_NOT_FIRED);
      ErrorMonitor.captureMessage('Callback time out', {
        isAuthenticated,
        isLoading
      });
    }
    try {
      console.log('callback timeout 123');
      await getAccessTokenSilently();
    } catch (error) {
      ErrorMonitor.captureException(error);
      redirectToLogin();
    }
  }, 5000);

  return <div style={{ backgroundColor: '#fff' }}></div>;
};

export default observer(Callback);
